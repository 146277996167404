<template>
    <div class="home">

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        
        <el-form-item label="姓名">
          <el-input  v-model="form.name"></el-input>
        </el-form-item>
  
        <el-form-item label="邮箱">
          <el-input  v-model="form.email"></el-input>
        </el-form-item>

        <el-form-item label="手机号">
          <el-input  v-model="form.phone"></el-input>
        </el-form-item>

        <el-form-item  label="密码">
          <el-input  v-model="form.password" show-password></el-input>
          <div style="position: absolute;top:0;right:-305px;width:300px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少6位</div>
        </el-form-item>

        
        <el-form-item  label="确认密码">
          <el-input  v-model="form.password_confirmation" show-password></el-input>
          <div style="position: absolute;top:0;right:-305px;width:300px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少6位</div>
        </el-form-item>

        <el-form-item  label="角色">
          <el-select multiple disabled
    collapse-tags v-model="form.role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          password_confirmation:'',
          password: '',
          phone: '',
          email: '',
          name: '',
          role:[]
        },
        options:[],
    }
  },
  methods: {
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      if(postdata.password != postdata.password_confirmation){
        _this.$message.error('密码不一致');
        return
      }
      if(postdata.password){
        var boo = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,16}$/.test(postdata.password);
        if(!boo){
            _this.$message.error('密码至少包含大写字母，小写字母，数字，且不少于6位!');
            return
        }
      }
      let json = {
          name:postdata.name,
          email:postdata.email,
          phone:postdata.phone,
          password:postdata.password,
          password_confirmation:postdata.password_confirmation,};
      axios.post(config.userInfoEdit,json)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/views/logo');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(){
        let _this = this;
        axios.get(config.userInfoSelf,{})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.form.role = [];
                for(let i in _this.form.user_role){
                  _this.form.role.push(_this.form.user_role[i].role_id);
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    initoptions(){
        let _this = this;
        axios.get(config.role,{params:{page:1,status:1,limit:999}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].role_id;
          arr.push(obj);
         }
          _this.options = arr;
        }
    }
  },
  created(){
      this.init();
      this.initoptions();
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
</style>